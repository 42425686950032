import React from 'react';

const Btnsmedia = () => {
  return (
    <div >
          <a href="https://www.instagram.com/nexustech10?igsh=dmFya2dkYXJwZDBz" target="_blank" rel="noopener noreferrer">
            <img className="iBtns-madia" src="https://cdn.nexustech.com.co/images/ICON_INSTAGRAM_OK.png" alt="Instagram" />
          </a>
          <a href="https://t.me/NexusTech10" target="_blank" rel="noopener noreferrer">
            <img className="iBtns-madia" src="https://cdn.nexustech.com.co/images/ICON_TELEGRAM_OK.png" alt="Telegram" />
          </a>
          <a href="https://wa.me/+573012078603" target="_blank" rel="noopener noreferrer">
            <img className="iBtns-madia" src="https://cdn.nexustech.com.co/images/ICON_WHATSAPP_OK.png" alt="Instagram" />
          </a>
          <a href="https://www.youtube.com/@NexusTech-global" target="_blank" rel="noopener noreferrer">
            <img className="iBtns-madia" src="https://cdn.nexustech.com.co/images/ICON_YOUTUBE_OK.png" alt="Youtube" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61556328205972&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <img className="iBtns-madia" src="https://cdn.nexustech.com.co/images/Icono_facebook_ready.png" alt="facebook" />
          </a>
    </div>
  );
};

export default Btnsmedia;