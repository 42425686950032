import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import Axios from 'axios'; 
import './FooterPage.css';
import BotonesMedia from '../../../partscomp/Btnsmedia'; 

const FooterPage = () => {  
  const [useMobileComponent, setUseMobileComponent] = useState(false);
  const [iPais, setCountry] = useState(null);
  const [iCiudad, setCity] = useState(null);

  useEffect(() => { 
    Axios.get(`https://inviw.cobiano.com/ingsux/ipquery/reverse`)
      .then((response) => {
        if (response.data.reqip === false) {
          // Manejar el caso de error aquí si es necesario
        } else { 
          const Pais = response.data.vContry;
          const Ciudad = response.data.vCity;       
          setCountry(Pais);        
          setCity(Ciudad);           
        }
      })
      .catch((error) => {
        // Manejar errores de la solicitud a la API
        console.error('Ups, Algo salió mal al obtener la IP');
      }); 
  }, []);

  const renderizarBotonesDMedia = () => {
    return window.innerWidth < 520 ? (
      <div className="iBox-media">
        <BotonesMedia />
      </div>
    ) : null;
  };
  const renderizarBotonesMMedia = () => {
    return window.innerWidth > 520 ? (
      <div className='iFooter-pie-m'>
        <BotonesMedia />
      </div>
    ) : null;
  };

  useEffect(() => {
    const handleResize = () => {
      setUseMobileComponent(window.innerWidth < 520);
    }; 
    window.addEventListener('resize', handleResize); 
    handleResize(); 
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="iFooter" id="Link"> 
      <div className="container">
      {renderizarBotonesDMedia()}
        <div className="iFooter-column">
          <div className="iFooter-pie">
            <div className='iFooter-pie-l'>               
                <img className="ImgIcon-madia" src="https://cdn.nexustech.com.co/images/Isotipo_N_Tech.png" alt="NexusTech" /> 
              <span className="text-14-s tbg-withe">&nbsp;All right&nbsp;&copy; nexustech.com.co</span>
            </div>    
              {renderizarBotonesMMedia()} 
            <div className='iFooter-pie-r'> 
                <button className="Btn-Slred Ttnorms" >CONTÁCTENOS</button> 
            </div> 
          </div>
        </div>
      </div>
    </div >
  );
};

export default FooterPage;
